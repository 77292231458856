@import '../../styles/theme.scss';

.intro {

  &-wrapper {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    color: $white;
    background-color: $usafa-blue;
    background: linear-gradient(to bottom, $usafa-blue 0 80%, $dark-cornflower-blue);
  }

  &-title-wrapper {
    padding: 2rem;
  }

  &-title {
    font-size: 5rem;
    margin: 1rem 0;
    font-weight: 400;

    @media (max-width: 700px) {
      font-size: 3rem;
    }
  }

  &-subtitle {
    font-size: 3rem;
    font-weight: 300;
    margin: 1rem 0;

    @media (max-width: 700px) {
        font-size: 1.5rem;
      }
  }

  &-scroll-tip {
    position: absolute;
    bottom: 1rem;
    opacity: 0.7;
    padding: 1rem;
  }

  &-scroll-arrows {
    animation: bounce-y 2s infinite cubic-bezier(0.28, 0.84, 0.42, 1);
    font-size: 2.5rem;
  }
}

#tsparticles {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}