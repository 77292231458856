@import '../../styles/theme.scss';

.skills {

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 6rem;
    background: $oxford-blue;
    background: linear-gradient(to bottom, $dark-cornflower-blue, $oxford-blue 30% 100%);
  }

}

.skill-item-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 800px;
  padding: 2rem;
}

.skill-item {
  color: $white;
  font-size: 4rem;
  padding: 2rem;
}