@import '../../styles/theme.scss';

.toolbar {


  &-wrapper {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 1rem;
    border: 1px solid $white;
    border-right: none;
    border-radius: 1rem 0 0 1rem;
    color: $white;
    text-align: center;
    z-index: 99;
    background-color: $usafa-blue;
    display: flex;
    flex-direction: column;

    a {
      text-decoration: none;
      color: $white;
      font-size: 2rem;

      & + a {
        margin-top: 0.35rem;
      }
    }
  }
}