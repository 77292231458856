@import '../../styles/theme.scss';

.about {

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 6rem 0 1rem;
    background: $dark-cornflower-blue;
    flex-wrap: wrap;
  }

  &_left {

    .white-border {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px;
      border: 1px solid rgba($white, 0.5);
      border-radius: 50%;

      & > .white-border {
        padding: 20px;
        border-width: 2px;
        border-color: rgba($color: $white, $alpha: 0.75);

        & > .white-border {
          padding: 10px;
          border-width: 5px;
          border-color: $white;
        }
      }
    }

    img {
      max-width: 200px;
      max-height: 200px;
      border-radius: 50%;
    }
  }

  &_right {
    padding: 2rem;
    color: white;
    max-width: 1000px;
  }
}