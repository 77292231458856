// colors
$black: #000000 !default;
$eerie-black: #202020 !default;
$davys-grey: #505050 !default;
$gray-web: #808080 !default;
$quick-silver: #a6a6a6 !default;
$light-gray: #cbcbcb !default;
$cultured: #f8f8f8 !default;
$white: #ffffff !default;

$paradise-pink: #e63462 !default;
$dark-pink: #cb2a59 !default;

$midnight-blue: #000040 !default;
$navy-blue: #000080 !default;
$trypan-blue: #0000c0 !default;
$medium-blue: #1010d2 !default;
$blue: #2020e4 !default;
$blue-ryb: #4040e8 !default;
$medium-slate-blue: #8080f0 !default;
$lavender-blue: #c0c0f8 !default;
$lavender-web: #e0e0fc !default;
$white: #ffffff !default;

$true-blue: #0466c8;
$usafa-blue: #0353a4;
$dark-cornflower-blue: #023e7d;
$oxford-blue: #002855;
$oxford-blue-2: #001845;
$oxford-blue-3: #001233;
$independence: #33415c;
$black-coral: #5c677d;
$roman-silver: #7d8597;
$manatee: #979dac;

@keyframes bounce-y {
  0% {
    transform: translateY(0);
  }

  10% {
    transform: translateY(6px);
  }

  20% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(6px);
  }

  40% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}