@import '../../styles/theme.scss';



.ex {

  &-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10rem 0;
    background: $dark-cornflower-blue;
  }

  &-path {
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0;
    bottom: 0;
    z-index: 0;
    background: linear-gradient(transparent 0 2%, $white, transparent 98% 100%);

    &_left {
      left: 0;
    }

    &_right {
      right: 0;
    }
  }

  &-item {
    position: relative;
    z-index: 2;
    max-width: 600px;
    padding: 2rem;
    color: white;
    border: 1px $white solid;
    border-radius: 2rem;
    background: $dark-cornflower-blue;

    &:not(:last-of-type) {
      margin-bottom: 8rem;
    }

    &-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}